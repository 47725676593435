<template>
   <section class="inventory h-100">
      <div class="h-100">
         <div class="grid-margin h-100">
            <div class="h-100 container-fluid px-5">
                
                <div class='h-100 mt-5'  v-if='$store.state.system[cdata.system._route_].is_all_fin_for_this_page' >
                    
                    
                    
                    
                    
                    
<!-- Modal -->
<div v-if="cdata.business_logic.show_inv_operation_modal" class="custom-modal" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="hide_info">
                      <span aria-hidden="true">&times;</span>
                    </button>
          
                        <div class="inv_info_modal" v-if='cdata.business_logic.selected_resource != null'>
                            <div class="p-3 d-flex justify-content-between align-items-center flex-column h-100">
                                <div class="d-flex align-items-center text-center">
                                    <p class="inv_info_name" v-html="cdata.business_logic.selected_resource.item_name"></p>
                                </div>
                                
                                <div class="d-flex align-items-center justify-content-center inv_info_img_outer">
                                    <img v-if="cdata.business_logic.selected_resource.item_img != ''" class="inv_info_img" :src="cdata.business_logic.selected_resource.item_img">
                                    <img v-else src="/resource/img/inventory_glossary/unknown.svg" class="inv_info_img" alt="unknown"/>
                                </div>
                                
                                <div class="d-flex align-items-center">
                                    <p v-html="cdata.business_logic.selected_resource.description" class="inv_info_desc"></p>
                                </div>
                                
                                
                                <div class="inv_shop d-flex flex-column w-100 mt-5" v-if="!(cdata.business_logic.selected_resource.buy_price == '0' || cdata.business_logic.selected_resource.sell_price == '0')">
                                    <form class='row'>
                                        <input id='myinput' class="w-100 form-range" type="range" min="1" max="100" step="1" v-model='cdata.business_logic.shop_range_amount'>
                                         <!--@input="myScript($event)"-->
                                        
                                        <div class="my-3 d-flex justify-content-between w-100">
                                            <input class="input_amount" type="text" pattern="[1-100]" maxlength="3" v-model='cdata.business_logic.shop_range_amount'>
                                            <p class="inv_shop_all_btn" @click="inv_info_all_btn">All</p>
                                        </div>
                                        
                                        <div class='mt-3 w-100 d-flex justify-content-between'>
                                            <button @click.prevent='buy_item' type="submit" class="inv_shop_btn inv_shop_buy_btn" :disabled='Number(cdata.business_logic.shop_range_amount) == 0 || (Number(cdata.business_logic.shop_range_amount) * Number(cdata.business_logic.selected_resource.buy_price)) > Number(state.players_gold) || (cdata.system.buttons.common.is_button_blocked)'>Buy <br><span class="d-flex justify-content-center align-items-center">-{{ cdata.business_logic.shop_range_amount * cdata.business_logic.selected_resource.buy_price }}<img class="ml-1" src="/resource/img/game_ico/gold.svg" alt="image" style="width: 24px;"/></span></button>
                                            <button @click.prevent='sell_item' type="submit" class="inv_shop_btn inv_shop_sell_btn" :disabled='Number(cdata.business_logic.shop_range_amount) == 0 || (Number(cdata.business_logic.shop_range_amount) > Number(cdata.business_logic.selected_resource.item_count)) || (cdata.system.buttons.common.is_button_blocked) || (cdata.business_logic.selected_resource.item_id.split("_")[0] == "prof")'>Sell <br><span class="d-flex justify-content-center align-items-center">+{{ cdata.business_logic.shop_range_amount * cdata.business_logic.selected_resource.buy_price }}<img class="ml-1" src="/resource/img/game_ico/gold.svg" alt="image" style="width: 24px;"/></span></button>
                                        </div>
                                        
                                    </form>
                                </div>
                                
                            </div>
                        </div>
      </div>
    </div>
  </div>
</div>  
                    
                    
                    
                    
                    
                    <div class="row" v-click-outside="hide_info" v-if="cdata.business_logic.window_width > 1000 && cdata.business_logic.window_height > 700">
                        <div class="inv_outer col-8">
                            
                            <div class="inv_resources mb-5">
                                <p class="category_name mb-4">Charges</p>
                                <div class="row m-0">
                                    <div v-for='resource in state.player_inventory.resources' :key='resource.id' class="inv_cell col-1 noselect mr-4 d-flex flex-column mb-4 p-0">
                                        
                                        <div id="inv_cell_buy_effect" :class="'inv_cell_buy_effect_id_' + resource.item_id">
                                            <p>+{{cdata.business_logic.last_operation_amount}}</p>
                                        </div>
                                        <div id="inv_cell_sell_effect" :class="'inv_cell_sell_effect_id_' + resource.item_id">
                                            <p>-{{cdata.business_logic.last_operation_amount}}</p>
                                        </div>
                                        
                                        <!--MUST BE HERE-->
                                        <div class="inv_cell_cover" current_type='resources' :current_id=resource.item_id @click='show_info($event, false)'></div>
                                        
                                        
                                        <div class="inv_cell_img_outer d-flex justify-content-center align-items-center">
                                            <img v-if="resource.item_img != ''" :src="resource.item_img" alt="unknown" style="max-width: 45px; max-height: 60px;"/>
                                            <img v-else src="/resource/img/inventory_glossary/unknown.svg" alt="unknown"/>
                                        </div>
                                        <div :class="((resource.item_count > 0) ? 'plus_item_count inv_cell__resources_count_outer' : 'zero_item_count inv_cell__resources_count_outer')">
                                            <p class="inv_cell__resources_count" v-html='resource.item_count'></p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="inv_items mb-5">
                                <p class="category_name mb-4">Items</p>
                                <div class="row m-0">

                                    <div v-for='item in state.player_inventory.items' :key='item.id' class="inv_cell col-1 noselect mr-4 d-flex flex-column mb-4 p-0">
                                        
                                        
                                        <div id="inv_cell_buy_effect" :class="'inv_cell_buy_effect_id_' + item.item_id">
                                            <p>+{{cdata.business_logic.last_operation_amount}}</p>
                                        </div>
                                        <div id="inv_cell_sell_effect" :class="'inv_cell_sell_effect_id_' + item.item_id">
                                            <p>-{{cdata.business_logic.last_operation_amount}}</p>
                                        </div>
                                        
                                        <div class="inv_cell_cover" current_type='items' :current_id=item.item_id @click='show_info($event, false)'></div>
                                        
                                        <div class="inv_cell_img_outer d-flex justify-content-center align-items-center">
                                            <img v-if="item.item_img != ''" :src="item.item_img" alt="unknown" style="max-width: 45px; max-height: 60px;"/>
                                            <img v-else src="/resource/img/inventory_glossary/unknown.svg" alt="unknown"/>
                                        </div>
                                        <div :class="((item.item_count > 0) ? 'plus_item_count inv_cell__resources_count_outer' : 'zero_item_count inv_cell__resources_count_outer')">
                                            <p class="inv_cell__resources_count" v-html='item.item_count'></p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            
                            <div class="inv_items mb-5">
                                <p class="category_name mb-4">Boosters</p>
                                <div class="row m-0">

                                    <div v-for='booster in state.player_inventory.prof_boosters' :key='booster.id' class="inv_cell col-1 noselect mr-4 d-flex flex-column mb-4 p-0">
                                        
                                        
                                        <div id="inv_cell_buy_effect" :class="'inv_cell_buy_effect_id_' + booster.item_id">
                                            <p>+{{cdata.business_logic.last_operation_amount}}</p>
                                        </div>
                                        <div id="inv_cell_sell_effect" :class="'inv_cell_sell_effect_id_' + booster.item_id">
                                            <p>-{{cdata.business_logic.last_operation_amount}}</p>
                                        </div>
                                        
                                        <div v-if="Number(booster.item_count) > -1" class="inv_cell_cover" current_type='prof_boosters' :current_id=booster.item_id @click='show_info($event, false)'></div>
                                        
                                        <div class="inv_cell_img_outer d-flex justify-content-center align-items-center">
                                            <img v-if="booster.item_img != ''" :src="booster.item_img" alt="unknown" style="max-width: 45px; max-height: 60px;"/>
                                            <img v-else src="/resource/img/inventory_glossary/unknown.svg" alt="unknown"/>
                                        </div>
                                        <div :class="((Number(booster.item_count) > 0) ? 'plus_item_count inv_cell__resources_count_outer' : 'zero_item_count inv_cell__resources_count_outer')">
                                            <p v-if="Number(booster.item_count) > -1" class="inv_cell__resources_count" v-html='(booster.item_count)'></p>
                                            <p v-else class="inv_cell__resources_count mdi mdi-lock" style="color: #2E3B4B;"></p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            
                        </div>
                        
                        
                        
                        <div class="inv_info offset-1 col-3" v-if='cdata.business_logic.selected_resource != null'>
                            <div class="p-3 d-flex justify-content-between align-items-center flex-column h-100">
                                <div class="d-flex align-items-center text-center">
                                    <p class="inv_info_name" v-html="cdata.business_logic.selected_resource.item_name"></p>
                                </div>
                                
                                <div class="d-flex align-items-center justify-content-center inv_info_img_outer">
                                    <img v-if="cdata.business_logic.selected_resource.item_img != ''" class="inv_info_img" :src="cdata.business_logic.selected_resource.item_img">
                                    <img v-else src="/resource/img/inventory_glossary/unknown.svg" class="inv_info_img" alt="unknown"/>
                                </div>
                                
                                <div class="d-flex align-items-center">
                                    <p v-html="cdata.business_logic.selected_resource.description" class="inv_info_desc"></p>
                                </div>
                                
                                
                                <div class="inv_shop d-flex flex-column w-100 mt-5" v-if="!(cdata.business_logic.selected_resource.buy_price == '0' || cdata.business_logic.selected_resource.sell_price == '0')">
                                    <form class='row'>
                                        <input id='myinput' class="w-100 form-range" type="range" min="1" max="100" step="1" v-model='cdata.business_logic.shop_range_amount'>
                                         <!--@input="myScript($event)"-->
                                        
                                        <div class="my-3 d-flex justify-content-between w-100">
                                            <input class="input_amount" type="text" pattern="[1-100]" maxlength="3" v-model='cdata.business_logic.shop_range_amount'>
                                            <p class="inv_shop_all_btn" @click="inv_info_all_btn">All</p>
                                        </div>
                                        
                                        <div class='mt-3 w-100 d-flex justify-content-between'>
                                            <button @click.prevent='buy_item' type="submit" class="inv_shop_btn inv_shop_buy_btn" :disabled='Number(cdata.business_logic.shop_range_amount) == 0 || (Number(cdata.business_logic.shop_range_amount) * Number(cdata.business_logic.selected_resource.buy_price)) > Number(state.players_gold) || (cdata.system.buttons.common.is_button_blocked)'>Buy <br><span class="d-flex justify-content-center align-items-center">-{{ cdata.business_logic.shop_range_amount * cdata.business_logic.selected_resource.buy_price }}<img class="ml-1" src="/resource/img/game_ico/gold.svg" alt="image" style="width: 24px;"/></span></button>
                                            <button @click.prevent='sell_item' type="submit" class="inv_shop_btn inv_shop_sell_btn" :disabled='Number(cdata.business_logic.shop_range_amount) == 0 || (Number(cdata.business_logic.shop_range_amount) > Number(cdata.business_logic.selected_resource.item_count)) || (cdata.system.buttons.common.is_button_blocked) || (cdata.business_logic.selected_resource.item_id.split("_")[0] == "prof")'>Sell <br><span class="d-flex justify-content-center align-items-center">+{{ cdata.business_logic.shop_range_amount * cdata.business_logic.selected_resource.buy_price }}<img class="ml-1" src="/resource/img/game_ico/gold.svg" alt="image" style="width: 24px;"/></span></button>
                                            <!--<button @click.prevent='sell_item' type="submit" class="inv_shop_btn inv_shop_sell_btn" :disabled='Number(cdata.business_logic.shop_range_amount) == 0 || (Number(cdata.business_logic.shop_range_amount) > Number(cdata.business_logic.selected_resource.item_count)) || (cdata.system.buttons.common.is_button_blocked)'>Sell <br><span class="d-flex justify-content-center align-items-center">+{{ cdata.business_logic.shop_range_amount * cdata.business_logic.selected_resource.buy_price }}<img class="ml-1" src="/resource/img/game_ico/gold.svg" alt="image"/></span></button>-->
                                        </div>
                                        
                                    </form>
                                </div>
                                
                            </div>
                        </div>
                        
                        <div class="inv_info_empty offset-1 col-3" style="padding-top: 5em;" v-else>
                            <div class="m-auto inv_info_img_outer" style="border: 1.5px solid #DCE0EA;">
                                <img src="/resource/img/inventory_glossary/unknown.svg" class="inv_info_img" alt="unknown"/>
                            </div>
                        </div>
                    </div>





                    <div class="row" v-else>
                        <div class="inv_outer col-12">
                            
                            <div class="inv_resources mb-5">
                                <p class="category_name mb-4">Charges</p>
                                <div class="row m-0 justify-content-between">
                                    <div v-for='resource in state.player_inventory.resources' :key='resource.id' class="inv_cell col-4 col-sm-2 noselect d-flex flex-column mb-4 p-0">

                                        <div id="inv_cell_buy_effect" :class="'inv_cell_buy_effect_id_' + resource.item_id">
                                            <p>+{{cdata.business_logic.last_operation_amount}}</p>
                                        </div>
                                        <div id="inv_cell_sell_effect" :class="'inv_cell_sell_effect_id_' + resource.item_id">
                                            <p>-{{cdata.business_logic.last_operation_amount}}</p>
                                        </div>

                                        <!--MUST BE HERE-->
                                        <div class="inv_cell_cover" current_type='resources' :current_id=resource.item_id @click='show_info($event, true)'></div>


                                        <div class="inv_cell_img_outer d-flex justify-content-center align-items-center">
                                            <img v-if="resource.item_img != ''" :src="resource.item_img" alt="unknown" style="max-width: 45px; max-height: 60px;"/>
                                            <img v-else src="/resource/img/inventory_glossary/unknown.svg" alt="unknown"/>
                                        </div>
                                        <div :class="((resource.item_count > 0) ? 'plus_item_count inv_cell__resources_count_outer' : 'zero_item_count inv_cell__resources_count_outer')">
                                            <p class="inv_cell__resources_count" v-html='resource.item_count'></p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="inv_items mb-5">
                                <p class="category_name mb-4">Items</p>
                                <div class="row m-0 justify-content-between">

                                    <div v-for='item in state.player_inventory.items' :key='item.id' class="inv_cell col-4 col-sm-2 noselect d-flex flex-column mb-4 p-0">
                                        
                                        
                                        <div id="inv_cell_buy_effect" :class="'inv_cell_buy_effect_id_' + item.item_id">
                                            <p>+{{cdata.business_logic.last_operation_amount}}</p>
                                        </div>
                                        <div id="inv_cell_sell_effect" :class="'inv_cell_sell_effect_id_' + item.item_id">
                                            <p>-{{cdata.business_logic.last_operation_amount}}</p>
                                        </div>
                                        
                                        <div class="inv_cell_cover" current_type='items' :current_id=item.item_id @click='show_info($event, true)'></div>
                                        
                                        <div class="inv_cell_img_outer d-flex justify-content-center align-items-center">
                                            <img v-if="item.item_img != ''" :src="item.item_img" alt="unknown" style="max-width: 45px; max-height: 60px;"/>
                                            <img v-else src="/resource/img/inventory_glossary/unknown.svg" alt="unknown"/>
                                        </div>
                                        <div :class="((item.item_count > 0) ? 'plus_item_count inv_cell__resources_count_outer' : 'zero_item_count inv_cell__resources_count_outer')">
                                            <p class="inv_cell__resources_count" v-html='item.item_count'></p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            
                            <div class="inv_items mb-5">
                                <p class="category_name mb-4">Boosters</p>
                                <div class="row m-0 justify-content-between">

                                    <div v-for='booster in state.player_inventory.prof_boosters' :key='booster.id' class="inv_cell col-4 col-sm-2 noselect d-flex flex-column mb-4 p-0">
                                        
                                        
                                        <div id="inv_cell_buy_effect" :class="'inv_cell_buy_effect_id_' + booster.item_id">
                                            <p>+{{cdata.business_logic.last_operation_amount}}</p>
                                        </div>
                                        <div id="inv_cell_sell_effect" :class="'inv_cell_sell_effect_id_' + booster.item_id">
                                            <p>-{{cdata.business_logic.last_operation_amount}}</p>
                                        </div>
                                        
                                        <div v-if="Number(booster.item_count) > -1" class="inv_cell_cover" current_type='prof_boosters' :current_id=booster.item_id @click='show_info($event, true)'></div>
                                        
                                        <div class="inv_cell_img_outer d-flex justify-content-center align-items-center">
                                            <img v-if="booster.item_img != ''" :src="booster.item_img" alt="unknown" style="max-width: 45px; max-height: 60px;"/>
                                            <img v-else src="/resource/img/inventory_glossary/unknown.svg" alt="unknown"/>
                                        </div>
                                        <div :class="((Number(booster.item_count) > 0) ? 'plus_item_count inv_cell__resources_count_outer' : 'zero_item_count inv_cell__resources_count_outer')">
                                            <p v-if="Number(booster.item_count) > -1" class="inv_cell__resources_count" v-html='(booster.item_count)'></p>
                                            <p v-else class="inv_cell__resources_count mdi mdi-lock" style="color: #2E3B4B;"></p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            
                        </div>
                        
                        
                        
<!--                        <div class="inv_info offset-1 col-3" v-if='cdata.business_logic.selected_resource != null'>
                            <div class="p-3 d-flex justify-content-between align-items-center flex-column h-100">
                                <div class="d-flex align-items-center text-center">
                                    <p class="inv_info_name" v-html="cdata.business_logic.selected_resource.item_name"></p>
                                </div>
                                
                                <div class="d-flex align-items-center justify-content-center inv_info_img_outer">
                                    <img v-if="cdata.business_logic.selected_resource.item_img != ''" class="inv_info_img" :src="cdata.business_logic.selected_resource.item_img">
                                    <img v-else src="/resource/img/inventory_glossary/unknown.svg" class="inv_info_img" alt="unknown"/>
                                </div>
                                
                                <div class="d-flex align-items-center">
                                    <p v-html="cdata.business_logic.selected_resource.description" class="inv_info_desc"></p>
                                </div>
                                
                                
                                <div class="inv_shop d-flex flex-column w-100 mt-5" v-if="!(cdata.business_logic.selected_resource.buy_price == '0' || cdata.business_logic.selected_resource.sell_price == '0')">
                                    <form class='row'>
                                        <input id='myinput' class="w-100 form-range" type="range" min="1" max="100" step="1" v-model='cdata.business_logic.shop_range_amount'>
                                         
                                        <div class="my-3 d-flex justify-content-between w-100">
                                            <input class="input_amount" type="text" pattern="[1-100]" maxlength="3" v-model='cdata.business_logic.shop_range_amount'>
                                            <p class="inv_shop_all_btn" @click="inv_info_all_btn">All</p>
                                        </div>
                                        
                                        <div class='mt-3 w-100 d-flex justify-content-between'>
                                            <button @click.prevent='buy_item' type="submit" class="inv_shop_btn inv_shop_buy_btn" :disabled='Number(cdata.business_logic.shop_range_amount) == 0 || (Number(cdata.business_logic.shop_range_amount) * Number(cdata.business_logic.selected_resource.buy_price)) > Number(state.players_gold) || (cdata.system.buttons.common.is_button_blocked)'>Buy <br><span class="d-flex justify-content-center align-items-center">-{{ cdata.business_logic.shop_range_amount * cdata.business_logic.selected_resource.buy_price }}<img class="ml-1" src="/resource/img/game_ico/gold.svg" alt="image" style="width: 24px;"/></span></button>
                                            <button @click.prevent='sell_item' type="submit" class="inv_shop_btn inv_shop_sell_btn" :disabled='Number(cdata.business_logic.shop_range_amount) == 0 || (Number(cdata.business_logic.shop_range_amount) > Number(cdata.business_logic.selected_resource.item_count)) || (cdata.system.buttons.common.is_button_blocked) || (cdata.business_logic.selected_resource.item_id.split("_")[0] == "prof")'>Sell <br><span class="d-flex justify-content-center align-items-center">+{{ cdata.business_logic.shop_range_amount * cdata.business_logic.selected_resource.buy_price }}<img class="ml-1" src="/resource/img/game_ico/gold.svg" alt="image" style="width: 24px;"/></span></button>
                                        </div>
                                        
                                    </form>
                                </div>
                                
                            </div>
                        </div>-->
                        
<!--                        <div class="inv_info_empty offset-1 col-3" style="padding-top: 5em;" v-else>
                            <div class="m-auto inv_info_img_outer" style="border: 1.5px solid #DCE0EA;">
                                <img src="/resource/img/inventory_glossary/unknown.svg" class="inv_info_img" alt="unknown"/>
                            </div>
                        </div>-->
                    </div>













                    
                </div>

            </div>
         </div>
      </div>
   </section>
</template>

<script>
    import Vue from 'vue'
    import { mapGetters } from 'vuex'
            var cdata = {//Если не указать - не всключиться реактивность 
                /* COPYPASTE MODIFY */
                system: {
                    _route_: "g1_inventory", /* MODIFY */
                    is_section: {// store/index  -> is_required_sections
                        center: false, /* СЕКЦИИ НА СТРАНИЦЕ MODIFY  v-if='cdata.system.is_section.center'   */
                    },
                    is_stage: {// store/index  -> is_required_stages
                        center: false,
                    },
                    cnf: {},
                    buttons: {
                        common: {
                            is_button_blocked: true,
                            button_blocking_exeption_list: {}, //ДЛЯ ДЕФОЛТНЫХ КНОПОК!!!
                        },
                        common_without_symmary: {
                            is_button_blocked: true,
                            button_blocking_exeption_list:
                                    {
                                        //0: "r_auth", //r_auth  
                                        0: "json_version", //r_auth 
                                        1: "events_list", //r_auth 
                                        //2: "json_layout_xxxx__pos_xx", НЕТ, просто не добавлять на эту страницу динамические таблицы!!!
                                    }
                        },
                        button1: {
                            is_button_blocked: true,
                            button_blocking_exeption_list:
                                    {
                                        0: "r_auth", //r_auth
                                    }
                        },
                    },
                },
                errors: {
                    is_errors: false,
                    actions_error: {},
                },
                business_logic: {
                    shop_range_amount: 1,
                    selected_resource: null,
                    last_operation_amount: 0,
                    
                    window_width: window.innerWidth,
                    window_height: window.innerHeight,
                    show_inv_operation_modal: false,
                },
            }

    export default {
        name: 'inventory', /* MODIFY */
        data() {
            return {
                cdata: cdata,
            };
        },
        created() { },
        mounted() {
            document.getElementById('await_loader_outer').style.display = 'block';
            this.$store.dispatch('_system/vuex_set__route_', this.cdata.system._route_);
            this.$root._PAGE().on_mounted(this.$store, this.cdata); //обнулить флажки

            //IF NEEDED 

            setTimeout(() => {
                this.$store.dispatch('_system/vuex_set__is_mounted_execute', {'is_mounted_execute': true, '_route_': this.cdata.system._route_ });
                this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {});
            }, 300);
            //this.cdata.system.is_mounted_execute = true;    
            //this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {});
            
            
//            REMOVE???!!!!!!!!!!!!!!!!!!!!!!!!!!!!
            this.$store.dispatch('_json/vuex_set__json_data__back', this.$root._LOAD().json___global__level_settings__back(this.$store, this.cdata));
            this.$store.dispatch('_json/vuex_set__json_data__back', this.$root._LOAD().json___level_props__exp_by_tasks__back(this.$store, this.cdata));
            this.$store.dispatch('_json/vuex_set__json_data__back', this.$root._LOAD().json___level_props__solve_limit__back(this.$store, this.cdata));
            
            
            this.$nextTick(() => {
              window.addEventListener('resize', this.onResize);
            })
        },
        computed: {

            /* COPYPASTE */
            ...mapGetters(['__blocked_tables', '__is_required_sections', '__is_required_stages', '__tables', '__is_errors']),
            _system() {
                let debug = {};
                try {
                    debug['tables'] = this.__tables(this.cdata['system']['_route_']);
                    debug['is_errors'] = this.__is_errors(this.cdata['system']['_route_']);
                    debug['blockings'] = this.__blocked_tables(this.cdata['system']['_route_']);
                    debug['sections'] = this.__is_required_sections(this.cdata['system']['_route_']);
                    debug['stages'] = this.__is_required_stages(this.cdata['system']['_route_']);
                } catch (ex) {
                    console.log(ex);
                }
                return debug;
            },
            /* MODIFY */
            state() {
                let state = {};
                try {
                    /* MODIFY */ //ВЫБРАТЬ используемые МОДУЛИ --> подгрузятся ТАБЛИЦЫ в них
                    state['auth'] = this.$store.state['state_tables'].state_auth; /* MODIFY */
                    state['inventory_glossary'] = this.$store.state['state_tables'].state_game_settings.global__inventory_glossary;/* MODIFY */
//                    state['player_inventory'] = this.$store.state['state_tables'].state_game_stats.player_inventory;/* MODIFY */
                    
                    state['player_inventory'] = this.$root._LAYOUT().sort_player_inventory(this.$store, this.cdata, this.$store.state.is_loaded_stages.player_inventory);
                    
                    state['players_gold'] = this.$root._LAYOUT().get_players_gold(this.$store, this.cdata, this.$store.state.is_loaded_stages.player_inventory);
                    
                    
                } catch (ex) {
                    console.log(ex);
                }

                return state;
            },
            form() {
                let form = {}; // form.classnum


                form['cnt_loaded'] = this.$store.state.cnt_loaded;
                form['is_all_stage_fin'] = this.$store.state.is_all_stage_fin;
                form['classnum'] = this.$store.state.state_tables.state_auth.r_auth.hasOwnProperty('classnum') ? this.$store.state.state_tables.state_auth.r_auth['classnum'] : "-1";
                form['lid'] = this.$store.state.state_tables.state_auth.r_auth.hasOwnProperty('lid') ? this.$store.state.state_tables.state_auth.r_auth['lid'] : "-1";
                return form;
            },
            /* #ODIFY */
            /* #OPYPASTE */

        },
        watch: {
//            "cdata.system.buttons.common.is_button_blocked": {
//                handler: function (value) {
//                    if(value == true) { document.getElementById('await_loader_outer').style.display= 'block'; }
//                    else { document.getElementById('await_loader_outer').style.display = 'none'; }
//                }
//            },

            "cdata.business_logic.shop_range_amount": {
                handler: function () {
                    if(this.cdata.business_logic.shop_range_amount > 100) { this.cdata.business_logic.shop_range_amount = 100 }
let slider = document.getElementById('myinput');
console.log('jnjnkhbkb');
console.log(slider);
let min = slider.min;
let max = slider.max;
let value = this.cdata.business_logic.shop_range_amount;

slider.style.background = `linear-gradient(to right, #5FE1FF 0%, #5FE1FF ${(value-min)/(max-min)*100}%, rgba(220, 224, 234, 0.5) ${(value-min)/(max-min)*100}%, rgba(220, 224, 234, 0.5) 100%)`;
                }
            },
            
            /* COPYPASTE на каждое изменение при работе с таблицей два вызова на начало и завершение*/
            "form.cnt_loaded": {
                handler: function () {



                    //ПОСТОЯННО ВЫПОЛНЯЕТСЯ после выполнения всех стадий, на любое действие с таблицами
                    if (this.$store.state.is_all_stage_fin)
                    {
                        document.getElementById('await_loader_outer').style.display = 'none';
                        //console.log("EXECUTE AFTER ALL STAGE LOAD FIN [" + this.cdata.system._route_ + "]");
                    }


                    //ВЫПОЛНИТСЯ РАЗОВО - проверяем готовы ли стадии, для текущей страницы 
                    if (this.$store.state.system[this.cdata.system._route_].is_mounted_execute && !this.$store.state.system[cdata.system._route_].is_all_fin_for_this_page) {
                        //проверить завершение всех стадий для страницы (срабатывал раньше времени)
                        //set cdata.system.is_stages_fin_for_THIS_page
                        // console.log("CALC is_stages_fin_for_THIS_page");
                        if (this.$root._SYS().check_is_stages_fin_for_this_page(this.$store, this.cdata))
                        {
                            this.$store.dispatch('_system/vuex_set__is_all_fin_for_this_page',   {'is_all_fin_for_this_page': true, '_route_': this.cdata.system._route_ });
                            console.log("REGISTER ALL STAGE LOAD FIN - FOR PAGE [" + this.cdata.system._route_ + "]");
                        }


                    }


                    //ПОСТОЯННО ВЫПОЛНЯЕТСЯ после загрузки всех стадий для страницы, на любое действие с таблицами
                    if (this.$store.state.system[cdata.system._route_].is_all_fin_for_this_page)
                    {
                        //просто постоянно перещитываем счетчики и блокровки кнопок!
                        this.$root._SYS().watch_force_called_on_mounted_execute(this.$store, this.cdata, true);
                    }



                },
                deep: true, //чтобы детектил изменения вложенных в объекто элементов
                immediate: true, //При передаче immediate: true в опциях, коллбэк будет вызываться незамедлительно с текущим значением отслеживаемого выражения:
            },
        },
        methods: {
            
            inv_info_all_btn: function() {
                if(Number(this.cdata.business_logic.selected_resource.item_count) > 0 && Number(this.cdata.business_logic.selected_resource.item_count) <= 100){
                    this.cdata.business_logic.shop_range_amount = Number(this.cdata.business_logic.selected_resource.item_count);
                } else if(Number(this.cdata.business_logic.selected_resource.item_count) > 0 && Number(this.cdata.business_logic.selected_resource.item_count) > 100){
                    this.cdata.business_logic.shop_range_amount = 100;
                } else {
                    this.cdata.business_logic.shop_range_amount = 1;
                }
            },
            
            sell_item: async function(){
                let search_result = this.$store.state['state_tables'].state_game_stats.player_inventory.find(item => item.item_id == this.cdata.business_logic.selected_resource.item_id);
                let sell_amount = this.cdata.business_logic.shop_range_amount;
                if(search_result && sell_amount > 0 && sell_amount <= 100) {
                    if(Number(search_result.item_count) >= Number(sell_amount)){

                        this.cdata.business_logic.last_operation_amount = this.cdata.business_logic.shop_range_amount;
                        document.querySelector('.inv_cell_sell_effect_id_'+this.cdata.business_logic.selected_resource.item_id).classList.add('inv_cell_sell_cover');

                        await this.$store.dispatch('_data/vuex_update__multi_a_data__ls_or_back', this.$root._UPDATE().sell_item__multi_ls_and_back(this.$store, this.cdata));
                    }
                }
                
                let item_id = this.cdata.business_logic.selected_resource.item_id;
                this.cdata.business_logic.selected_resource = null; 
                this.cdata.business_logic.shop_range_amount = 1;
                
                var elems = document.querySelectorAll(".inv_cell .active");
                [].forEach.call(elems, function(el) {
                    document.querySelector('.inv_cell_sell_effect_id_'+item_id).classList.remove('inv_cell_sell_cover');
                    el.classList.remove("active");
                });
                
                if(this.cdata.business_logic.show_inv_operation_modal == true) { this.cdata.business_logic.show_inv_operation_modal = false; }
            },
            
            buy_item: async function(){
                let gold_amount = this.$store.state['state_tables'].state_game_stats.player_inventory.find(item => item.item_id == "res_gold");
//                let search_result = this.$store.state['state_tables'].state_game_stats.player_inventory.find(item => item.item_id == this.cdata.business_logic.selected_resource.item_id);
                
                if(gold_amount && this.cdata.business_logic.shop_range_amount > 0 && this.cdata.business_logic.shop_range_amount <= 100){
                    let gold_needed = (this.cdata.business_logic.shop_range_amount) * Number(this.cdata.business_logic.selected_resource.buy_price);
                    let deal_res = Number(gold_amount.item_count) - gold_needed;

                        if(deal_res >= 0){
this.cdata.business_logic.last_operation_amount = this.cdata.business_logic.shop_range_amount;
console.log("this.cdata.business_logic.selected_resource.item_id");
console.log(this.cdata.business_logic.selected_resource.item_id);
console.log(document.querySelector('.inv_cell_buy_effect_id_'+this.cdata.business_logic.selected_resource.item_id));
document.querySelector('.inv_cell_buy_effect_id_'+this.cdata.business_logic.selected_resource.item_id).classList.add('inv_cell_buy_cover');


                            await this.$store.dispatch('_data/vuex_update__multi_a_data__ls_or_back', this.$root._UPDATE().buy_item__multi_ls_and_back(this.$store, this.cdata));
                        }
                    
                    let item_id = this.cdata.business_logic.selected_resource.item_id;
                    
                    this.cdata.business_logic.selected_resource = null; 
                    this.cdata.business_logic.shop_range_amount = 1;

                    var elems = document.querySelectorAll(".inv_cell .active");
                    [].forEach.call(elems, function(el) {
                        document.querySelector('.inv_cell_buy_effect_id_'+item_id).classList.remove('inv_cell_buy_cover');
                        el.classList.remove("active");
                    });
                    
                    if(this.cdata.business_logic.show_inv_operation_modal == true) { this.cdata.business_logic.show_inv_operation_modal = false; }
                }
//cdata.business_logic.selected_resource.item_id
//let inv_cell_buy_effect = document.querySelector('.inv_cell_buy_effect_id_res_aluminum_ore');
//inv_cell_buy_effect.classList.toggle('inv_cell_buy_cover');
            },
            
            
            hide_info: function(event){
                if(!this.cdata.system.buttons.common_without_symmary.is_button_blocked){
                        var elems = document.querySelectorAll(".inv_cell .active");
                        [].forEach.call(elems, function(el) {
                            el.classList.remove("active");
                        });
                        this.cdata.business_logic.selected_resource = null; 
                        this.cdata.business_logic.shop_range_amount = 1;
                        
                        if(this.cdata.business_logic.show_inv_operation_modal == true) { this.cdata.business_logic.show_inv_operation_modal = false; }
                }
            },
            
            show_info: function (event, show_modal) {
                if(!this.cdata.system.buttons.common_without_symmary.is_button_blocked){
                    let current_type = event.target.getAttribute('current_type');
                    let current_id = event.target.getAttribute('current_id');
                    
                    if (window._STR().is_not_empty(current_type) && window._STR().is_not_empty(current_id)){
                        let search_result = null;
                        
                        search_result = this.state.player_inventory[current_type].find(glossary_item => glossary_item.item_id == current_id);
                        
                        if(search_result) {                            
                            var elems = document.querySelectorAll(".inv_cell .active");
                            [].forEach.call(elems, function(el) {
                                el.classList.remove("active");
                            });
                            
                            event.target.classList.toggle('active');
                            this.cdata.business_logic.shop_range_amount = 1;
                            this.cdata.business_logic.selected_resource = search_result;
                            
                            if(show_modal) { this.cdata.business_logic.show_inv_operation_modal = true; }
                            
                        }
                    }
                }
            },
            
            onResize() {
                this.cdata.business_logic.window_width = window.innerWidth;
                this.cdata.business_logic.window_height = window.innerHeight;
            },
        },
        
        beforeDestroy() {
            this.cdata.business_logic.shop_range_amount = 1;
            this.cdata.business_logic.selected_resource = null;
            this.cdata.business_logic.last_operation_amount = 0;
            
            window.removeEventListener('resize', this.onResize);
        }


    }
</script>

<style lang="scss" scoped>
    
    .inv_cell__resources_count{
        font-size: 14px;
        font-weight: 600;
        color: #FFFFFF;
        text-align: center;
    }
    
    .inv_cell__resources_count_outer {
          width: 65px; 
          height: 20px;
          border-radius: 0px 0px 12px 12px;
    }
    
    .plus_item_count {
        background: #F7D45A; 
        filter: drop-shadow(0px 4px 0px #E2C250); 
    }
    .zero_item_count {
        background: #DCE0EA;
        filter: drop-shadow(0px 4px 0px #CDD1DA);
    }
    
    .inv_cell_img_outer {
        width: 65px; 
        height: 72px; 
        background: #FFFFFF; 
        border: 2px solid #FFFFFF;
        border-radius: 12px 12px 0px 0px;
        transition: .2s ease-in-out;
    }
    
    .noselect {
      -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
         -khtml-user-select: none; /* Konqueror HTML */
           -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /* Non-prefixed version, currently
                                      supported by Chrome, Edge, Opera and Firefox */
    }
    
    .category_name {
        color: #2E3B4B;
        font-weight: 600;
        font-size: 20px;
    }
    
    .inv_cell_cover {
        position: absolute; 
        top: 0; 
        left: 0; 
/*        height: 100%; 
        width: 100%;*/
        width: 65px;
        height: 96px;
        z-index: 999;
    }
    
    #inv_cell_buy_effect {
        display:none;
        
        -webkit-animation: fadeInFromNone 1s ease-out;
        -moz-animation: fadeInFromNone 1s ease-out;
        -o-animation: fadeInFromNone 1s ease-out;
        animation: fadeInFromNone 1s ease-out;
    }
    
    #inv_cell_sell_effect{
        display:none;
        
        -webkit-animation: fadeInFromNone 1s ease-out;
        -moz-animation: fadeInFromNone 1s ease-out;
        -o-animation: fadeInFromNone 1s ease-out;
        animation: fadeInFromNone 1s ease-out;
    }
    
    .inv_cell_buy_cover {
        display:block !important;
        
        position: absolute; 
        top: 0; 
        left: 0;
        width: 65px;
        height: 96px;
        z-index: 500;
        
        -webkit-animation: fadeInFromNone 1s ease-in;
        -moz-animation: fadeInFromNone 1s ease-in;
        -o-animation: fadeInFromNone 1s ease-in;
        animation: fadeInFromNone 1s ease-in;
        
        & > p {
            color: #fff;
            font-size: 18px;
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    
    .inv_cell_buy_cover~.active+.inv_cell_img_outer {
        background: #49BDF4;
        border: 2px solid #F7D45A;
        transition: .4s ease-in-out;
        
        & > img{
            display:none;
        }
    }
    
    .inv_cell_sell_cover {
        display:block !important;
        
        position: absolute; 
        top: 0; 
        left: 0;
        width: 65px;
        height: 96px;
        z-index: 500;
        
        -webkit-animation: fadeInFromNone 1s ease-in;
        -moz-animation: fadeInFromNone 1s ease-in;
        -o-animation: fadeInFromNone 1s ease-in;
        animation: fadeInFromNone 1s ease-in;
        
        & > p {
            color: #fff;
            font-size: 18px;
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    
    .inv_cell_sell_cover~.active+.inv_cell_img_outer {
        background: #FF7697;
        border: 2px solid #F7D45A;
        transition: .4s ease-in-out;
        
        & > img{
            display:none;
        }
    }
    
    .active+.inv_cell_img_outer {
        background: #FFF7D8;
        border: 2px solid #F7D45A;
        transition: .2s ease-in-out;
    }
    
    
    .inv_info_empty {
        background: transparent;
        border: 1.5px solid #DCE0EA;
        /*filter: drop-shadow(0px 4px 16px rgba(180, 180, 180, 0.1));*/
        border-radius: 12px;
        height: 474px;
        margin-top: 3rem;
    }
    
    .inv_info {
        background: #FFFFFF;
        box-shadow: 0px 4px 16px rgba(180, 180, 180, 0.1);
        border-radius: 12px;
        /*padding: 20px;*/
        height: 474px;
        margin-top: 3rem;
    }
    
    
    .inv_info_name {
        font-weight: 500;
        font-size: 28px;
        color: #2E3B4B;
    }
    
    .inv_info_img_outer {
        width: 125px;
        height: 120px;
        background: #F3F4FB;
        border-radius: 12px;
        padding: 12px;
    }
    
    .inv_info_img {
        width: 96px;
        height: 96px;
    }
    
    .input_amount {
        padding: 4px 8px;
        border: 1px solid #DCE0EA;
        border-radius: 8px;
        width: 47px; 
        height: 28px;
        text-align: center;
        font-size: 18px;
        color: #2E3B4B;
    }
    
    .inv_shop_all_btn {
        cursor: pointer;
        background: #55D18E;
        box-shadow: 0px 2px 0px #4BB87D;
        border:none;
        border-radius: 8px;
        padding: 4px 12px;
        
        color: #FFFFFF;
        font-weight: 500;
        font-size: 16px;
    }
    
    
    .inv_shop_btn {
        font-size: 20px;
        padding: 4px 16px 4px;
        border-radius: 12px;
        color: #fff;
        border: none;
    }
    
    .inv_shop_btn:disabled {
        opacity: .5;
    }
    
    .inv_shop_buy_btn {
        background: #49BDF4;
        box-shadow: 0px 4px 0px #3EADE1;
        
        width: 135px;
    }
    
    .inv_shop_sell_btn {
        background: #FF7697;
        box-shadow: 0px 4px 0px #E66584;
        
        width: 135px;
    }
    
    .inv_info_desc {
        font-weight: 500;
        font-size: 16px;
        color: #2E3B4B;
    }
    
    
    
    

    
    
    
    
    
    
    
input[type='range'] {
    border-radius: 8px;
    height: 10px;
    outline: none;
    -webkit-appearance: none;
    /*background: linear-gradient(to right, rgb(95, 225, 255) 0%, rgb(95, 225, 255) 0%, rgb(222, 226, 230) 0%, rgb(222, 226, 230) 100%);*/
    background: linear-gradient(to right, rgb(95, 225, 255) 0%, rgb(95, 225, 255) 0%, rgba(220, 224, 234, 0.5) 0%, rgba(220, 224, 234, 0.5) 100%);
}

input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 10px;
    height: 23px;
    background: #27D3FA;
    box-shadow: 0px 2px 0px #27B2D1;
    border-radius: 12px;
    cursor: pointer;
}


@media (max-width: 1450px){
    .inv_shop_btn {
        font-size: 16px;
    }
    
    .inv_shop_buy_btn,
    .inv_shop_sell_btn {
        width: 110px;
    }

}

    .inv_info,
    .inv_info_empty{
        position: fixed;
        right: 5%;
    }
    
    
    /*MODAL*/
    .custom-modal {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1050;
        width: 100%;
        height: 100%;
        outline: 0;
        background-color: #00000059;
    }
    /*END MODAL*/
</style>
